import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from './../shared/shared.module';
import { ItauRouting } from './itau.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItauComponent } from './itau.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { BoletoFisicoComponent } from './boleto-fisico/boleto-fisico.component';
import { FormComponent } from './form/form.component';

@NgModule({
  declarations: [ItauComponent, BoletoFisicoComponent, FormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    ItauRouting,
    SharedModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgxMaskModule.forRoot()
  ]
})
export class ItauModule { }
