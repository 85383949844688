import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EstadoBr } from './../models/estado-br.model';
import { Cidade } from '../models/cidade';
import { map } from '../../../../node_modules/rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  constructor(private http: HttpClient) { }

  getEstadosBr() {
    return this.http.get<EstadoBr[]>('assets/dados/estadosbr.json');
  }
  cpfcnpjmask(rawValue) {
    try {
      var numbers = rawValue.match(/\d/g);
      var numberLength = 0;
      if (numbers) {
        numberLength = numbers.join('').length;
      }
      if (numberLength <= 11) {
        return [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
      } else {
        return [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
      }
    } catch (error) {
    }
  }

  foneMask(rawValue) {
    try {
      var numbers = rawValue.match(/\d/g);
      var numberLength = 0;
      if (numbers) {
        numberLength = numbers.join('').length;
      }
      if (numberLength <= 10) {
        return ['(', /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
      } else {
        return ['(', /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
      }
    } catch (error) {
    }
  }

  instrucaoDeCobranca() {
    return [
      { valor: '09', display: 'PROTESTAR' },
      { valor: '10', display: 'NÃO PROTESTAR ' }

    ];
  }

  digito = (valor: string, minCaracter: number = 0, maxCaracter: number = 0): string => {

    const regex = new RegExp('^\\d{' + minCaracter + ',' + maxCaracter + '}$', 'i');

    if (regex.test(valor)) {
      if (valor.length < maxCaracter) {
        let v = (maxCaracter - valor.length)
        let resultado = ""
        for (let index = 0; index < v; index++) {
          resultado += "0"
        }
        return resultado + valor
      } else
        return valor
    }
    return null;

  }



}
