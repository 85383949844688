import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Boleto from 'boleto.js';
import * as moment from 'moment';
import { ItauService } from '../itau.service';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-boleto-fisico',
  templateUrl: './boleto-fisico.component.html',
  styleUrls: ['./boleto-fisico.component.scss']
})
export class BoletoFisicoComponent implements OnInit, OnDestroy {
  inscricao: Subscription[] = [];
  numero;
  objeto: any;
  valorDocumento;
  cabecalho;
  endereco
  constructor(
    private itauService: ItauService,
    private localeService: BsLocaleService,
    private route: ActivatedRoute,

  ) {
    defineLocale('pt-br', ptBrLocale);
    localeService.use('pt-br');
  }




  ngOnInit() {
    this.inscricao.push(this.route.params.subscribe(
      (params: any) => {
        if (params.id) {
          // console.log('id recebido' + params.id)

          this.itauService.buscaDados(params.id).subscribe(res => {

            // console.log(res)
            this.itauService.enviar(res).subscribe(
              respon => {
                // console.log(respon)
                this.objeto = respon.corpo[0];
                this.cabecalho = respon.cabecalho;
                this.geraCodigDeBarra(respon.corpo[0]);
              }
            );

          })
        }
      }))


  }



  convertParaNumber(valor: string): number {
    if (valor) {
      return Number(valor);
    }

  }
  imprimir() {
    window.print();
  }

  ehCpfOuCnpj(valor: string) {
    if (valor) {
      const v = Number(valor).toString();
      if (v.length > 11) {
        return 'CNPJ';
      } else {
        return 'CPF';
      }
    }
  }

  cpfCnpj(valor: string) {
    if (valor) {
      let v = Number(valor).toString();

      if (v.length > 11) {
        if (v.length < 14) {
          for (let i = 0; i < (14 - v.length); i++) {
            v = '0' + v;
          }

        }
        return `${v.substring(0, 2)}.${v.substring(2, 5)}.${v.substring(5, 8)}/${v.substring(8, 12)}-${v.substring(12, 14)}`;
      } else {
        if (v.length < 11) {
          for (let i = 0; i < (11 - v.length); i++) {
            v = '0' + v;
          }
        }
        return `${v.substring(0, 3)}.${v.substring(3, 6)}.${v.substring(6, 9)}-${v.substring(9, 11)}`;

      }
    }
    return valor;
  }



  data(valor: string) {

    if (valor && valor !== '000000') {
      const dia = valor.substring(0, 2);
      const mes = valor.substring(2, 4);
      const ano = '20' + valor.substring(4, 6);
      return new Date(Number(ano), (Number(mes) - 1), Number(dia));
    } else {
      return null;
    }

  }

  calculoDac3PrimeirosCampos(campo: string): string {

    let prod = 2;
    let produto = 0;
    for (let i = campo.length; i > 0; i--) {

      const valor = (prod * Number(campo[i - 1]));
      if (valor > 9) {
        produto += Number(valor.toString()[0]) + Number(valor.toString()[1]);
      } else {
        produto += valor;
      }
      if (prod === 2) {
        prod = 1;
      } else {
        prod = 2;
      }
    }
    const resultado = (10 - (produto % 10));
    if (resultado === 10) {
      return '0';
    } else {
      return resultado.toString();
    }

  }


  geraCodigDeBarra(objeto) {
    // // console.log('this.objeto ====> ', objeto);

    let campo1 = objeto.codigoBanco + '9' + objeto.numeroCarteira + objeto.nossoNumero.substring(0, 2);
    campo1 = campo1 + this.calculoDac3PrimeirosCampos(campo1);

    let campo2 = objeto.nossoNumero.substring(2) +
      this.calculoDac3PrimeirosCampos(objeto.agencia + objeto.conta + objeto.numeroCarteira + objeto.nossoNumero) +
      objeto.agencia.substring(0, 3);
    campo2 = campo2 + this.calculoDac3PrimeirosCampos(campo2);

    let campo3 = objeto.agencia.substring(3) + objeto.conta + objeto.dac + '000';
    campo3 = campo3 + this.calculoDac3PrimeirosCampos(campo3);
    //// console.log(campo1.length + ' ' + campo1 + ' <> ' + campo2.length + ' ' + campo2 + ' <> ' + campo3.length + ' ' + campo3);

    const ultimoCampo = this.calculoFatorVencimento(objeto.vencimento.substring(0, 2),
      objeto.vencimento.substring(2, 4), '20' + objeto.vencimento.substring(4, 6)) +
      objeto.valorTitulo.substring(3, 13);



    // // console.log(semDacs.length);

    // // console.log((campo1 + campo2 + campo3  + ultimoCampo).length );
    // // console.log()

    const codig = campo1 + campo2 + campo3 +
      this.dacCodigoDeBarras43(
        objeto.codigoBanco + '9' +
        this.calculoFatorVencimento(objeto.vencimento.substring(0, 2),
          objeto.vencimento.substring(2, 4), '20' + objeto.vencimento.substring(4, 6)) +
        objeto.valorTitulo.substring(3, 13) +
        objeto.numeroCarteira + objeto.nossoNumero + this.dacNossoNumero() +
        objeto.agencia + objeto.conta + objeto.dac +
        '000')
      + ultimoCampo;

    // // console.log(codig);
    this.numero = new Boleto(codig).prettyNumber();
    this.valorDocumento = new Boleto(codig).amount();
    // 34191091233456780158329400010004878700000010000  correto
    // 3419109123 456780158 2940001000  78700000010000
    // 34191.09123 34567.801583 29400.010004 8 78700000010000
    new Boleto(codig).toSVG('#boleto');
  }


  calculoFatorVencimento(dia, mes, ano): string {
    // // console.log('dia ' + dia + ' mes ' + mes + ' ano ' + ano);
    const inicio = moment('03/07/2000', 'DD/MM/YYYY');
    const fim = moment(`${dia}/${mes}/${ano}`, 'DD/MM/YYYY');
    const dias = fim.diff(inicio, 'days');
    let resposta = (dias + 1000);
    if (resposta > 9999) {
      resposta = (resposta - 10000) + 1000;
    }
    return resposta.toString();
  }
  // calcula o dac recebe 43 caracters de valor
  dacCodigoDeBarras43(valor: string): string {
    // console.log('dac43 = ', valor)
    let v = 2;
    let somatorio = 0;
    for (let i = valor.length; i > 0; i--) {
      // // console.log(Number(valor[i - 1])+ ' * ' +v+ ' = '+ (Number(valor[i - 1]) * v))
      somatorio += (Number(valor[i - 1]) * v);
      ++v;
      if (v === 10) {
        v = 2;
      }
    }
    // console.log('somatorio ', somatorio)
    let restoDivisao = (somatorio % 11)
    // console.log('restoDivisao ', restoDivisao)
    let dac = (11 - restoDivisao)
    return dac.toString();
  }


  dacNossoNumero() {
    if (this.objeto) {
      return this.calculoDac3PrimeirosCampos(this.objeto.agencia +
        this.objeto.conta + this.objeto.numeroCarteira + this.objeto.nossoNumero);
    }

  }


  getArquivoRemessa() {
    const arquivoRemessa = `{

      "cabecalho": {
        "agencia": "1582",
        "conta": "94000",
        "dac": "1",
        "nomeEmpresa": "GASP SAÚDE S NEGÓCIOS LTDA",
      },
      "endereco":{
        enderecoEmpresa: "Rua dos Timbiras, 2683 Loja 4 Santo Agostinho BH - MG, 30.140-063"
      }
      "corpo": [{
        "numeroInscricaoEmpresa": "31.965.899/0001-00",
        "agencia": "1582",
        "conta": "94000",
        "dac": "1",
        "nossoNumero": "12345678",
        "numeroDocumento": "7654321",
        "vencimento": "2019-04-25T17:58:46.504Z",
        "valorTitulo": "100.00",
        "juros1dia": "00",
        "descontoAte": "",
        "valorDesconto": "00",
        "valorIOF": "00",
        "abatimento": "00",
        "numeroInscricaoPagador": "01532289626",
        "nome": "thulio amaral",
        "logradouro": "rua a",
        "bairro": "centro",
        "cep": "32900000",
        "cidade": "igarape",
        "estado": "mg",
        "sacadorAvalista": "",
        "dataMora": "",
        "prazo": "60",
      }]
    }`;
    return JSON.parse(arquivoRemessa);


  }

  ngOnDestroy() {
    this.inscricao.map(item => {
      item.unsubscribe()
    })
  }
}
