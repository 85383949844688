import { EmpresaComponent } from './empresa.component';
import { EBradescoComponent } from './e-bradesco/e-bradesco.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '', component: EmpresaComponent, children: [
      { path: 'bradesco', component: EBradescoComponent },
      { path: 'bradesco/:id', component: EBradescoComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class EmpresaRouting { }
