import { EmpresaService } from './../empresa.service';
import { BradescoService } from './../../bradesco/bradesco.service';
import { Subscription } from 'rxjs';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-e-bradesco',
  templateUrl: './e-bradesco.component.html',
  styleUrls: ['./e-bradesco.component.scss']
})
export class EBradescoComponent implements OnInit, OnDestroy {
  formulario: FormGroup;
  habilitarEdicao = false;
  inscricao: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private empresaService: EmpresaService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.criarForm();
    this.teste();
    this.getProdutoEdicao();
  }

  getProdutoEdicao() {
    this.inscricao.push(this.route.params.subscribe(
      (params: any) => {

        if (params.id) {

          this.inscricao.push(this.empresaService.getConfigBradesco(params.id).subscribe(
            res => {
              console.log(res);
              if (Object.keys(res).length !== 0) {
                this.habilitarEdicao = true;
                this.formulario.setValue(res);
              } else {

              }
            }
          ));
        }
      }));
  }

  submit() {

    if (this.formulario.valid) {
      this.inscricao.push(this.empresaService.saveConfigBradesco(this.formulario.value).subscribe(res => {
        console.log(res);
      }));

    }
  }

  teste() {
    let a = `01REMESSA01COBRANCA       00000000000004848115GASP GRUPO ASSISTENCIAL SOLIDA237BRADESCO       280319        MX0004246                                                                                                                                                                                                                                                                                     000001`
    console.log('cod empresa = ', a.substring(26, 46));
    console.log('nome empresa = ', a.substring(46, 76));
    console.log('id sistema = ', a.substring(108, 110));
    console.log('n seque remessa = ', a.substring(110, 117));
    console.log('n seque registro = ', a.substring(394, 400));
    let b = `1000000000000000000000090341500366927                         0000000000000000000000000000001N           2  01372994    01041900000000079950000000005N280319189900000000000000000000000000000000000000000000000000000000000100006393265523MARCUS VINICIUS SOUZA DO NASCIMENTO     RUA IRMA JOANA BARROS,23 CS 03,URUGUAI,S            40450680NAO RECEBER VALOR INFERIOR.                                 000002`
    console.log('agencia = ', b.substring(24, 29));
    console.log('conta = ', b.substring(29, 36));
    console.log('d conta = ', b.substring(36, 37));
    console.log('carteira = ', b.substring(21, 24));
  }



  criarForm() {
    this.formulario = this.formBuilder.group({
      id: [null],
      codEmpresa: [null, [Validators.max(99999999999999999999), Validators.required]],
      nomeEmpresa: [null, [Validators.maxLength(30), Validators.required]],
      idSistema: [null, [Validators.maxLength(2), Validators.required]],
      numeroSequencialRemessa: [null, [Validators.max(9999999), Validators.required]],
      numeroSequencialRegistro: [null, [Validators.max(999999), Validators.required]],
      carteira: [null, [Validators.max(999), Validators.required]],
      agencia: [null, [Validators.max(99999), Validators.required]],
      conta: [null, [Validators.max(9999999), Validators.required]],
      digConta: [null, [Validators.max(9), Validators.required]],
    });
  }

  ngOnDestroy() {
    this.inscricao.map(item => {
      item.unsubscribe();
    });
  }
}
