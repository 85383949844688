import { BoletoFisicoComponent } from './boleto-fisico/boleto-fisico.component';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { BradescoRouting } from './bradesco.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BradescoComponent } from './bradesco.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FormComponent } from './form/form.component';

@NgModule({
  declarations: [
    BradescoComponent,
    FormComponent,
    BoletoFisicoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    BradescoRouting,
    SharedModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgxMaskModule.forRoot()
  ]
})
export class BradescoModule { }
