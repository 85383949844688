import { EmpresaComponent } from './empresa.component';
import { EmpresaRouting } from './empresa.routing';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EBradescoComponent } from './e-bradesco/e-bradesco.component';
import { EItauComponent } from './e-itau/e-itau.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    EBradescoComponent,
    EItauComponent,
    EmpresaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    EmpresaRouting

  ]
})
export class EmpresaModule { }
