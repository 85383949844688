import { EmpresaService } from '../../empresa/empresa.service';
import { ActivatedRoute } from '@angular/router';
import { BradescoService } from '../bradesco.service';
import { FormValidations } from '../../shared/form-validations';
import { DropdownService } from '../../shared/services/dropdown.service';
import { ConsultaCepService } from '../../shared/services/consulta-cep.service';
import { EstadoBr } from '../../shared/models/estado-br.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, empty } from 'rxjs';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  formulario: FormGroup;
  estados: EstadoBr[];
  inscricao: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private cepService: ConsultaCepService,
    private dropdownService: DropdownService,
    private localeService: BsLocaleService,
    private bradescoService: BradescoService,
    private route: ActivatedRoute,
    private empresaService: EmpresaService
  ) {
    defineLocale('pt-br', ptBrLocale);
    localeService.use('pt-br');
  }

  ngOnInit() {
    this.criarForm();
    this.getEstados();
    this.escutaAlteracaoCep();
    this.arquivoCorpo();
    this.getProdutoEdicao();
    this.cabecalho();

  }

  getProdutoEdicao() {
    this.inscricao.push(this.route.params.subscribe(
      (params: any) => {

        if (params.id) {

          this.inscricao.push(this.empresaService.getConfigBradesco(params.id).subscribe(
            res => {
              console.log(res);
              if (Object.keys(res).length !== 0) {
                const idEmpresa = '0' +
                  this.dropdownService.digito(res.carteira, 1, 3) +
                  this.dropdownService.digito(res.agencia, 1, 5) +
                  this.dropdownService.digito(res.conta, 1, 7) +
                  this.dropdownService.digito(res.digConta, 1, 1);
                this.formulario.get('cabecalho.id').setValue(res.id);
                this.formulario.get('corpo.idEmpresaBeneficiariaBanco').setValue(idEmpresa);
              } else {

              }
            }
          ));
        }
      }));
  }

  arquivoCorpo() {
    let a = `1000000000000000000000090341500366927                         0000000000000000000000000000001N           2  01372998    28031900000000079200000000005N280319189900000000000000000000000000000000000000000000000000000000000100004842271574LEANDRO DE OLIVEIRA                     RUA THOMAZ GONZAGA,11,CONDOMINIO SaO JUD            41100000NAO RECEBER VALOR INFERIOR.                                 000010`;
    console.log('idRegistro', a.substring(0, 1));
    console.log('agenciaDebito', a.substring(1, 6));
    console.log('digitoAgenciaDebito', a.substring(6, 7));
    console.log('razaoContaCorrente', a.substring(7, 12));
    console.log('contaCorrente', a.substring(12, 19));
    console.log('digitoContaCorrente', a.substring(19, 20));
    console.log('idEmpresaBeneficiariaBanco', a.substring(20, 37));
    console.log('numeroControleParticipante', a.substring(37, 62));
    console.log('codigoBancoDebitadoCamaraCompensacao', a.substring(62, 65));
    console.log('campoMulta', a.substring(65, 66));
    console.log('percentualMulta', a.substring(66, 70));
    console.log('identificacaoTituloBanco', a.substring(70, 81));
    console.log('digitoAutoConferenciaNumeroBancario', a.substring(81, 82));
    console.log('descontoBonificacaoDia', a.substring(82, 92));
    console.log('condicaoEmissaoPapeletaCobranca', a.substring(92, 93));
    console.log('identEmiteBoletoDebitoAutomatico', a.substring(93, 94));
    console.log('identificacaoOperacaoBanco', a.substring(94, 104));
    console.log('indicadorRateioCredito', a.substring(104, 105));
    console.log('endAvisoDebitoAutomaticoContaCorrente', a.substring(105, 106));
    console.log('quantidadePossiveisPagamento', a.substring(106, 108));
    console.log('identificacaoOcorrencia', a.substring(108, 110));
    console.log('numDocumento', a.substring(110, 120));
    console.log('dataVencimentoTitulo', a.substring(120, 126));
    console.log('valorTitulo', a.substring(126, 139));
    console.log('bancoEncarregadoCobranca', a.substring(139, 142));
    console.log('agenciaDepositaria', a.substring(142, 147));
    console.log('especieTitulo', a.substring(147, 149));
    console.log('identificacao', a.substring(149, 150));
    console.log('dataEmissaoTitulo', a.substring(150, 156));
    console.log('instrucao1', a.substring(156, 158));
    console.log('instrucao2', a.substring(158, 160));
    console.log('valorCobradoPorDiaAtraso', a.substring(160, 173));
    console.log('dataLimiteConcessaoDesconto', a.substring(173, 179));
    console.log('valorDesconto', a.substring(179, 192));
    console.log('valorIOF', a.substring(192, 205));
    console.log('valorAbatimentoConcedidoCancelado', a.substring(205, 218));
    console.log('identificaçãoTipoInscricaoPagador', a.substring(218, 220));
    console.log('numeroInscricaoPagador', a.substring(220, 234));
    console.log('nomePagador', a.substring(234, 274));
    console.log('endereçoCompleto', a.substring(274, 314)); //
    console.log('mensagem1', a.substring(314, 326));
    console.log('CEP', a.substring(326, 331));
    console.log('sufixoCEP', a.substring(331, 334));
    console.log('sacadorAvalistaOuMensagem2', a.substring(334, 394));
    console.log('numeroSequencialRegistro', a.substring(394, 400));

  }



  opcional() {
    let a = `2Refere-se ao mes anterior. Baixe o APP e descubra os seus beneficios            Android: facil auto / Aplle: gasp | facil auto 31-3347-1001/4007-2521                                                                                           -PWC5722                                                                                                                     0090341500366927000000000000000009`;
    console.log('Tipo Registro', a.substring(0, 1));
    console.log('Mensagem 1', a.substring(1, 81));
    console.log('Mensagem 2', a.substring(81, 161));
    console.log('Mensagem 3', a.substring(161, 241));
    console.log('Mensagem 4', a.substring(241, 321));
    console.log('Data limite para concessão de Desconto 2', a.substring(321, 327));
    console.log('Valor do Desconto', a.substring(327, 340));
    console.log('Data limite para concessão de Desconto 3 ', a.substring(340, 346));
    console.log('Valor do Desconto', a.substring(346, 359));
    console.log('Reserva', a.substring(359, 366));
    console.log('Carteira', a.substring(366, 369));
    console.log('Agência', a.substring(369, 374));
    console.log('Conta Corrente ', a.substring(374, 381));
    console.log('Dígito C/C ', a.substring(381, 382));
    console.log('Nosso Número', a.substring(382, 393));
    console.log('DAC Nosso Número', a.substring(393, 394));
    console.log('Nº Seqüencial de Registro ', a.substring(394, 400));
  }

  cabecalho() {
    let a = `01REMESSA01COBRANCA       00000000000004848115GASP GRUPO ASSISTENCIAL SOLIDA237BRADESCO       280319        MX0004246                                                                                                                                                                                                                                                                                     000001`;
    console.log('codEmpresa', a.substring(26, 46));
    console.log('nomeEmpresa', a.substring(46, 76));
    console.log('idSistema', a.substring(108, 110));
    console.log('numeroSequencialRemessa', a.substring(110, 117));
    console.log('numeroSequencialRegistro', a.substring(294, 400));
  }

  getEstados() {
    this.inscricao.push(this.dropdownService.getEstadosBr()
      .subscribe(dados => this.estados = dados));
  }

  escutaAlteracaoCep() {

    this.inscricao.push(this.formulario.get('endereco.cep').statusChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(status => status === 'VALID' ?
          this.cepService.consultaCEP(this.formulario.get('endereco.cep').value)
          : empty()
        )
      )
      .subscribe(dados => dados ? this.populaDadosForm(dados) : {}));
  }

  submit() {

    if (this.formulario.valid) {
      this.inscricao.push(this.bradescoService.enviar(this.formulario.value)
        .subscribe(res => {
          console.log(res);
        }));
    }
    console.log(this.formulario);
  }

  populaDadosForm(dados) {

    this.formulario.patchValue({
      endereco: {
        logradouro: dados.logradouro,

        complemento: dados.complemento,
        bairro: dados.bairro,
        cidade: dados.localidade,
        estado: dados.uf
      }
    });
  }

  criarForm() {
    this.formulario = this.formBuilder.group({
      cabecalho: this.formBuilder.group({
        id: [null]
      }),
      corpo: this.formBuilder.group({
        idEmpresaBeneficiariaBanco: [null],
        numDocumento: [null, [Validators.required]],
        dataVencimentoTitulo: [null],
        valorTitulo: [null],
        numeroInscricaoPagador: [null],
        nomePagador: [null],
        endereçoCompleto: [null],
        CEP: [null],
        sacadorAvalistaOuMensagem2: [null],
        numeroSequencialRegistro: [null]
      }),
      endereco: this.formBuilder.group({
        id: [null],
        cep: [null, [Validators.required, FormValidations.cepValidator]],
        numero: [null, Validators.required],
        complemento: [null],
        logradouro: [null, Validators.required],
        bairro: [null, Validators.required],
        cidade: [null, Validators.required],
        estado: [null, Validators.required]
      })


    });
  }

  ngOnDestroy() {
    this.inscricao.map(item => {
      item.unsubscribe();
    });
  }
}








/*


erver {

 root /var/www/dev.thulio.net.br/html;

        index index.php index.html index.htm index.nginx-debian.html;

        server_name dev.thulio.net.br www.dev.thulio.net.br;


        #location / {
                #try_files $uri $uri/ =404;
        #}

        location / {

                proxy_pass http://localhost:3002;
                proxy_http_version 1.1;
                proxy_set_header Upgrade $http_upgrade;
                proxy_set_header Connection 'upgrade';
                proxy_set_header Host $host;
                proxy_cache_bypass $http_upgrade;

        }

        location ~ \.php$ {
                include snippets/fastcgi-php.conf;
                fastcgi_pass unix:/var/run/php/php7.2-fpm.sock;
        }

        location ~ /\.ht {
                deny all;
        }


    #listen [::]:443 ssl ipv6only=on; # managed by Certbot
    listen 443 ssl; # managed by Certbot
    ssl_certificate /etc/letsencrypt/live/dev.thulio.net.br/fullchain.pem; # managed by Certbot
    ssl_certificate_key /etc/letsencrypt/live/dev.thulio.net.br/privkey.pem; # managed by Certbot
    include /etc/letsencrypt/options-ssl-nginx.conf; # managed by Certbot
    ssl_dhparam /etc/letsencrypt/ssl-dhparams.pem; # managed by Certbot


}
server {
 if ($host = www.dev.thulio.net.br) {
        return 301 https://$host$request_uri;
    } # managed by Certbot


    if ($host = dev.thulio.net.br) {
        return 301 https://$host$request_uri;
    } # managed by Certbot



        listen 80;

        listen [::]:80;

        server_name dev.thulio.net.br www.dev.thulio.net.br;
    return 404; # managed by Certbot

}


*/
/*

server {

        root /var/www/thulio.net.br/html;

        index index.php index.html index.htm index.nginx-debian.html;

        server_name thulio.net.br www.thulio.net.br;


        #location / {
                #try_files $uri $uri/ =404;
        #}

        location / {

                proxy_pass http://localhost:3001;
                proxy_http_version 1.1;
                proxy_set_header Upgrade $http_upgrade;
                proxy_set_header Connection 'upgrade';
                proxy_set_header Host $host;
                proxy_cache_bypass $http_upgrade;

        }

        location ~ \.php$ {
                include snippets/fastcgi-php.conf;
                fastcgi_pass unix:/var/run/php/php7.2-fpm.sock;
        }

        location ~ /\.ht {
                deny all;
        }


    #listen [::]:443 ssl ipv6only=on; # managed by Certbot
    listen 443 ssl; # managed by Certbot
    ssl_certificate /etc/letsencrypt/live/thulio.net.br/fullchain.pem; # managed by Certbot
    ssl_certificate_key /etc/letsencrypt/live/thulio.net.br/privkey.pem; # managed by Certbot
    include /etc/letsencrypt/options-ssl-nginx.conf; # managed by Certbot
    ssl_dhparam /etc/letsencrypt/ssl-dhparams.pem; # managed by Certbot


}
server {
    if ($host = www.thulio.net.br) {
        return 301 https://$host$request_uri;
    } # managed by Certbot


    if ($host = thulio.net.br) {
        return 301 https://$host$request_uri;
    } # managed by Certbot



        listen 80;

        listen [::]:80;

        server_name thulio.net.br www.thulio.net.br;
    return 404; # managed by Certbot




}



*/



