
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bradesco',
  templateUrl: './bradesco.component.html',
  styleUrls: ['./bradesco.component.scss']
})
export class BradescoComponent implements OnInit {
  constructor(
  ) {

  }

  ngOnInit() {
  }
}
