
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer' + ' ' + localStorage.getItem('access_token') })
};


@Injectable({
  providedIn: 'root'
})
export class BradescoService {
  private readonly apiUrl = `${environment.API}/bradesco`;



  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.status);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  enviar(data): Observable<any> {
    return this.http.post(this.apiUrl, data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

}
