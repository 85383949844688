import { EmpresaModule } from './empresa/empresa.module';
import { BradescoModule } from './bradesco/bradesco.module';
import { ItauModule } from './itau/itau.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'itau', loadChildren: () => ItauModule },
  { path: 'bradesco', loadChildren: () => BradescoModule },
  { path: 'config', loadChildren: () => EmpresaModule },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
