import { ItauService } from './itau.service';
import { FormValidations } from './../shared/form-validations';
import { DropdownService } from './../shared/services/dropdown.service';
import { ConsultaCepService } from './../shared/services/consulta-cep.service';
import { EstadoBr } from './../shared/models/estado-br.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, empty } from 'rxjs';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';


@Component({
  selector: 'app-itau',
  templateUrl: './itau.component.html',
  styleUrls: ['./itau.component.scss']
})
export class ItauComponent implements OnInit, OnDestroy {
  formulario: FormGroup;
  estados: EstadoBr[];
  inscricao: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private cepService: ConsultaCepService,
    private dropdownService: DropdownService,
    private localeService: BsLocaleService,
    private itauService: ItauService
  ) {
    defineLocale('pt-br', ptBrLocale);
    localeService.use('pt-br');
  }

  ngOnInit() {
    this.criarForm();
    this.getEstados();

  }

  getEstados() {
    this.inscricao.push(this.dropdownService.getEstadosBr()
      .subscribe(dados => this.estados = dados));
  }

  escutaAlteracaoCep() {

    this.inscricao.push(this.formulario.get('endereco.cep').statusChanges
      .pipe(
        distinctUntilChanged(),
        switchMap(status => status === 'VALID' ?
          this.cepService.consultaCEP(this.formulario.get('endereco.cep').value)
          : empty()
        )
      )
      .subscribe(dados => dados ? this.populaDadosForm(dados) : {}));
  }

  submit() {

    if (this.formulario.valid) {
      this.inscricao.push(this.itauService.enviar(this.formulario.value)
        .subscribe(res => {
          console.log(res);
        }));
    }
    console.log(this.formulario);
  }

  populaDadosForm(dados) {

    this.formulario.patchValue({
      endereco: {
        logradouro: dados.logradouro,

        complemento: dados.complemento,
        bairro: dados.bairro,
        cidade: dados.localidade,
        estado: dados.uf
      }
    });
  }

  criarForm() {
    this.formulario = this.formBuilder.group({
      cabecalho: this.formBuilder.group({
        agencia: [233, [Validators.max(9999), Validators.required]],
        conta: [1223, [Validators.max(99999), Validators.required]],
        dac: [2, [Validators.max(9), Validators.required]],
        nomeEmpresa: ['NOME EMPRESA', [Validators.required, Validators.maxLength(30)]],
        numeroSequencial: [1, [Validators.max(999999), Validators.required]]
      }),
      corpo: this.formBuilder.group({
        codigoInscricao: [1, [Validators.required, Validators.maxLength(2)]],
        numeroInscricaoEmpresa: [1, [Validators.max(99999999999999), Validators.required]],
        agencia: [1, [Validators.max(9999), Validators.required]],
        conta: [1, [Validators.max(99999), Validators.required]],
        dac: [1, [Validators.max(9), Validators.required]],
        usoEmpresa: ['USO DA EMPRESA', [Validators.maxLength(25)]],
        nossoNumero: [1, [Validators.max(99999999), Validators.required]],
        numeroCarteira: [1, [Validators.max(999), Validators.required]],
        usoBanco: ['USO DO BANCO', [Validators.required, Validators.maxLength(21)]],
        carteira: ['U', [Validators.required, Validators.maxLength(1)]],
        codigoOcorrencia: [1, [Validators.max(99), Validators.required]],
        numeroDocumento: [1, [Validators.max(9999999999), Validators.required]],
        vencimento: [new Date(), [Validators.required]],
        valorTitulo: [null],
        agenciaCobradora: [1, [Validators.max(99999), Validators.required]],
        especie: ['R$', [Validators.required, Validators.maxLength(2)]],
        aceite: [null, [Validators.required, Validators.maxLength(1)]],
        instruçao1: ['AA', [Validators.required, Validators.maxLength(2)]],
        instrucao2: ['AA', [Validators.required, Validators.maxLength(2)]],
        juros1dia: [null],
        descontoAte: [new Date(), [Validators.required]],
        valorDesconto: [null],
        valorIOF: [null],
        abatimento: [null],
        numeroInscricaoPagador: [null, [Validators.max(99999999999999), Validators.required]],
        nome: ['NOME', [Validators.required, Validators.maxLength(30)]],
        logradouro: ['LOGRADOURO', [Validators.required, Validators.maxLength(40)]],
        bairro: ['BAIRRO', [Validators.required, Validators.maxLength(12)]],
        cep: ['CEP', [Validators.required, Validators.maxLength(8)]],
        cidade: ['CIDADE', [Validators.required, Validators.maxLength(15)]],
        estado: ['MG', [Validators.required, Validators.maxLength(2)]],
        sacadorAvalista: ['SACADOR AVALISTA', [Validators.required, Validators.maxLength(30)]],
        dataMora: [new Date(), [Validators.required]],
        prazo: [1, [Validators.max(99), Validators.required]],
        numeroSequecial: [1, [Validators.max(999999), Validators.required]]

      })
    });
  }

  ngOnDestroy() {
    this.inscricao.map(item => {
      item.unsubscribe();
    });
  }
}


/*
*

 -> CODIGO DE INSCRICAO
 TIPO/Nº DE INSCRIÇÃO DA EMPRESA/SACADOR

    01 Nº DO CPF DO BENEFICIÁRIO
    02 Nº DO CNPJ DO BENEFICIÁRIO
    03 CPF DO SACADOR
    04 CNPJ DO SACADOR



*/
