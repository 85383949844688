import { FormComponent } from './form/form.component';
import { BoletoFisicoComponent } from './boleto-fisico/boleto-fisico.component';
import { BradescoComponent } from './bradesco.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '', component: BradescoComponent, children: [
      { path: 'fisico', component: BoletoFisicoComponent },
      { path: 'form/:id', component: FormComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class BradescoRouting { }
